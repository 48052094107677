<template>
  <svg width="18"
       height="18"
       viewBox="0 0 18 18"
       fill="none"
       xmlns="http://www.w3.org/2000/svg"
  >
    <path fill="#9e9e9e" d="M13.57 0a.745.745 0 100 1.49h1.886L13.94 3.008a5.274 5.274 0 00-3.197-1.068c-.97 0-1.9.257-2.712.74a5.282 5.282 0 00-2.71-.74A5.283 5.283 0 001.56 3.498 5.285 5.285 0 000 7.26c0 1.421.554 2.757 1.559 3.762a5.278 5.278 0 003.015 1.506v1.171H3.088a.745.745 0 100 1.49h1.486v1.486a.744.744 0 101.49 0v-1.486H7.55a.745.745 0 100-1.49H6.065v-1.171a5.264 5.264 0 001.965-.688 5.29 5.29 0 002.712.74c1.42 0 2.757-.554 3.76-1.558A5.284 5.284 0 0016.06 7.26c0-1.17-.375-2.28-1.068-3.197l1.518-1.518v1.887a.744.744 0 101.49 0V.745A.745.745 0 0017.255 0H13.57zM5.32 3.43c.508 0 1 .098 1.455.285A5.28 5.28 0 005.421 7.26c0 1.321.48 2.568 1.353 3.545a3.822 3.822 0 01-1.453.284 3.808 3.808 0 01-2.709-1.12A3.806 3.806 0 011.49 7.259c0-1.022.399-1.983 1.122-2.707A3.806 3.806 0 015.321 3.43zm5.422 0c1.023 0 1.984.399 2.705 1.122A3.804 3.804 0 0114.57 7.26a3.804 3.804 0 01-1.12 2.709 3.804 3.804 0 01-2.707 1.12c-.507 0-1-.098-1.455-.284a5.28 5.28 0 001.352-3.545 5.276 5.276 0 00-1.353-3.544 3.825 3.825 0 011.456-.285zM8.03 4.556a3.803 3.803 0 011.119 2.703A3.804 3.804 0 018.03 9.965a3.805 3.805 0 01-1.12-2.706c0-1.02.397-1.98 1.119-2.703z" />
  </svg>
</template>

<script>
    export default {
        name: "Gender"
    }
</script>
